import { useState, useEffect, useContext } from 'react';
import { Alert, Snackbar } from '@mui/material/';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { FunctionsLoginContext } from './FunctionsLoginContext'
//import PropTypes from 'prop-types'

function Alerts(props) {
  const [alertProps, setAlertProps] = useState({ type: 'error', message: '', show: false });

  const functionsLoginContext = useContext(FunctionsLoginContext);


  useEffect(() => {
    const types = ["error", "warning", "info", "success"];
    if (types.includes(props.alertProps.type)) {
      let type = props.alertProps?.type ? props.alertProps.type : 'error';
      let message = props.alertProps?.message ? props.alertProps.message : '';
      let show = props.alertProps?.show ? props.alertProps.show : false;
      setAlertProps({ type: type, message: message, show: show })
    }
  }, [props])



  return (
    <Snackbar open={alertProps.show} autoHideDuration={6000} sx={{ bottom: { lg: 8, xs: 60 } }}>
      <Alert variant="filled" severity={alertProps.type}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              functionsLoginContext.hideAlert()
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >{alertProps.message}</Alert>
    </Snackbar>
  )
}

export default Alerts