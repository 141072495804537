
const apiURL = "/api"

const headers = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
}

export const getProviders = () =>
  fetch(`${apiURL}/providers/`, { headers })
    .then(res => res.json())
    .then(data => data?.data?.providers)
    .catch(err => console.log(err))

export const getGenres = (token) =>
  fetch(`${apiURL}/genres/`, {
    method: 'POST',
    headers: {
      ...headers,
    },
    body: JSON.stringify({
      token: token
    })
  })
    .then(res => res.json())
    .then(data => data?.data?.genres)
    .catch(err => console.log(err))


export const sendMail = (name, email, message) =>
  fetch(`${apiURL}/mail`, {
    method: 'POST',
    headers: {
      ...headers
    },
    body: JSON.stringify({ name: name, email: email, message: message })
  }).then(res => res.json())
    .then(data => data)
    .catch(err => console.log(err))


export const logout = () =>
  fetch(`${apiURL}/logout`, {
    method: 'POST',
    headers: {
      ...headers,
    },
    body: JSON.stringify({
    })
  })
    .then(res => res.json())
    .then(data => data)
    .catch(err => console.log(err))

export const login = (providerID, username, password, remember) =>
  fetch(`${apiURL}/login`, {
    method: 'POST',
    headers: {
      ...headers,
    },
    body: JSON.stringify({
      providerID: providerID,
      username: username,
      password: password,
      remember: remember,
    })
  })
    .then(res => res.json())
    .then(data => data)
    .catch(err => console.log(err))

export const getMedia = (token, filter = {}) =>
  fetch(`${apiURL}/media`, {
    method: 'POST',
    headers: {
      ...headers,
    },
    body: JSON.stringify({
      token: token,
      filter: filter
    })
  })
    .then(res => res.json())
    .then(data => data)
    .catch(err => console.log(err))

export const getMediaCache = (token, filter = {}) =>
  fetch(`${apiURL}/media_cache`, {
    method: 'POST',
    headers: {
      ...headers,
    },
    body: JSON.stringify({
      token: token,
      filter: filter
    })
  })
    .then(res => res.json())
    .then(data => data)
    .catch(err => console.log(err))

export const getDownloadHistory = (token, filter = {}) =>
  fetch(`${apiURL}/download_history`, {
    method: 'POST',
    headers: {
      ...headers,
    },
    body: JSON.stringify({
      token: token,
      filter: filter
    })
  })
    .then(res => res.json())
    .then(data => data)
    .catch(err => console.log(err))

export const getPreviewHistory = (token) =>
  fetch(`${apiURL}/preview_history`, {
    method: 'POST',
    headers: {
      ...headers,
    },
    body: JSON.stringify({
      token: token,
    })
  })
    .then(res => res.json())
    .then(data => data)
    .catch(err => console.log(err))

export const updateQueue = (token, mediaId) =>
  fetch(`${apiURL}/queue/update`, {
    method: 'POST',
    headers: {
      ...headers,
    },
    body: JSON.stringify({
      token: token,
      mediaId: mediaId
    })
  })
    .then(res => res.json())
    .then(data => data)
    .catch(err => console.log(err))



export const addToQueue = (token, mediaId) =>
  fetch(`${apiURL}/queue/add`, {
    method: 'POST',
    headers: {
      ...headers,
    },
    body: JSON.stringify({
    token: token,
      mediaId: mediaId
    })
  })
    .then(res => res.json())
    .then(data => data)
    .catch(err => console.log(err))



  export const removeFromQueue = (token, mediaId) =>
  fetch(`${apiURL}/queue/remove`, {
    method: 'POST',
    headers: {
      ...headers,
    },
    body: JSON.stringify({
      token: token,
      mediaId: mediaId
    })
  })
    .then(res => res.json())
    .then(data => data)
    .catch(err => console.log(err))


export const downloadMedia = (token, mediaId, isVideo) =>
  fetch(`${apiURL}/download`, {
    method: 'POST',
    headers: {
      ...headers,
    },
    body: JSON.stringify({
      token: token,
      mediaId: mediaId,
      isVideo: isVideo
    })
  })
    .then(res => res.json())
    .then(data => data)
    .catch(err => console.log(err))

export const previewMedia = (token, mediaId) =>
  fetch(`${apiURL}/preview`, {
    method: 'POST',
    headers: {
      ...headers,
    },
    body: JSON.stringify({
      token: token,
      mediaId: mediaId
    })
  })
    .then(res => res.json())
    .then(data => data)
    .catch(err => console.log(err))

export const getCrate = (token) =>
  fetch(`${apiURL}/crate`, {
    method: 'POST',
    headers: {
      ...headers,
    },
    body: JSON.stringify({
      token: token
    })
  })
    .then(res => res.json())
    .then(data => data)
    .catch(err => console.log(err))

export const getCharts = (token, days) =>
  fetch(`${apiURL}/charts`, {
    method: 'POST',
    headers: {
      ...headers,
    },
    body: JSON.stringify({
      token: token,
      days: days
    })
  })
    .then(res => res.json())
    .then(data => data)
    .catch(err => console.log(err))