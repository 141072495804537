import { Link } from 'react-router-dom'
import { Button, Container, Grid } from '@mui/material/';

function Body(props) {

  return (
    <Grid id="top" container spacing={2} style={{ marginTop: 0 }}>
      <Grid item xs={12} style={{ backgroundColor: '#0D214A', padding: '80px 0' }}>
        <Container sx={{ mx: "auto", width: 334 }}>
          <Link to="/">
            <img alt="crateQ" src="/img/logo-crateq.png" />
          </Link>
        </Container>
      </Grid>
      <Grid item xs={12} style={{ backgroundColor: '#E7E7E7', padding: '70px 0' }}>
        <Container className="text-center" xs={8}>
          <p className="mui--text-display2 font-700">Black crateQ playback screen?</p>
          <img alt="crateQ black screen" src="/img/crateq-black-screen.jpg" style={{ marginTop: 60, maxWidth: '96%' }} />
          <p className="mui--text-title" style={{ maxWidth: 800, margin: '30px auto' }}>
            Some Windows machines may require an additional codec for full video and audio preview functionality in crateQ. So, if your crateQ playback window is black, please download and install the latest version of DivX below.
          </p>
          <p className="mui--text-title" style={{ maxWidth: 800, margin: '30px auto' }}>
            Once installed, please close the non-working preview/playback pop-up window & click the "preview" icon again. You should now be seeing/hearing video!
          </p>
          <Container>
            <Button variant="flat" size="large" className="btn-divx-dwn font-600" href="https://download.divx.com/stable/divx/DivXInstaller.exe">DOWNLOAD Divx</Button>
          </Container>
          <img alt="Divx logo" src="/img/logo-divx.png" />
        </Container>
      </Grid>
      <Grid item xs={12} style={{ backgroundColor: '#000000', minHeight: 140 }}>
      </Grid>
    </Grid>
  )
}

export default Body