import * as React from 'react'
import { Box, Container, Divider, Grid, TextField } from '@mui/material/';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import ClearIcon from '@mui/icons-material/Clear';
import * as APIClient from '../../utils/APIClient'

//import PropTypes from 'prop-types'

export default function Footer(props) {

  const [stickyFormOpen, setFormOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [errors, setErrors] = React.useState({ erName: false, erEmail: false, erMessage: false });
  const [formDisabled, setFormDisabled] = React.useState(false);
  const [mailErrorMessage, setMailErrorMessage] = React.useState('');

  function changeName(val) {
    setName(val);
  }

  function changeEmail(val) {
    setEmail(val);
  }

  function changeMessage(val) {
    setMessage(val);
  }

  function stickyFormToggle() {
    var element = document.querySelector(".sticky-form");
    element.classList.toggle("open");
    stickyFormOpen ? resetForm() : null;
    setFormOpen(!stickyFormOpen);
  }

  function contactResultToggle() {
    let form = document.querySelector("#contact-form");
    let result = document.querySelector("#contact-result");
    form.classList.add("hidden");
    result.classList.remove("hidden");
  }

  function resetForm() {
    let form = document.querySelector("#contact-form");
    let result = document.querySelector("#contact-result");
    form.classList.remove("hidden");
    result.classList.add("hidden");
    setName('');
    setEmail('');
    setMessage('');
  }

  function submitForm() {
    let newErrors = { erName: false, erEmail: false, erMessage: false };
    let allowSubmit = true;
    if (name === '') {
      newErrors.erName = true;
      allowSubmit = false;
    }
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (email === '' || !email.match(validRegex)) {
      newErrors.erEmail = true;
      allowSubmit = false;
    }

    if (message === '') {
      newErrors.erMessage = true;
      allowSubmit = false;
    }
    setErrors(newErrors);
    if (allowSubmit) {
      setFormDisabled(true);
      setLoading(true);
      APIClient.sendMail(name, email, message)
        .then(
          (data) => {
            setFormDisabled(false);
            setLoading(false);
            if (data.eCode === 0) {
              contactResultToggle();
            }
            else {
              setMailErrorMessage(data.eMsg);
            }
          }
        )
        .catch((err) => {
          setFormDisabled(false);
          setLoading(false);
          setMailErrorMessage('Failed to send email')
        }
        )
    }
  }

  return (
    <Container>
      <Divider />
      <Grid container style={{ margin: '40px auto', padding: '0 20px', maxWidth: '1140px', textAlign: 'center' }}>
        <Grid item xs={12}>
          <p className="mui--text-title text-secondry">© {(new Date().getFullYear())} crateQ . All Rights Reserved</p>
        </Grid>
      </Grid>
      <Container className='help-btn' style={{ padding: 0 }} onClick={stickyFormToggle}>
        <MailOutlineIcon className="mui--text-light" style={{ display: (stickyFormOpen) ? 'none' : 'block' }} />
        <ClearIcon className="mui--text-light" style={{ display: (stickyFormOpen) ? 'block' : 'none' }} />
      </Container>
      <Container className="sticky-form">
        <div className="sticky-form-title mui--text-light">
          <h5>Have a question, need support?</h5>
          <p>We reply within 48 hours.</p>
        </div>
        <img className="assistant-avatar" src="/img/loader.png" alt="assistant-avatar"></img>
        <div role="form" id="contact-form" >
          <div>
            <p></p>
          </div>
          <div className="sticky-form-holder">
            <Container disableGutters={true}>
              <p className="mui--text-danger" style={{ padding: 6 }}>{mailErrorMessage}</p>
              <Box component="form">
                <TextField
                  required
                  id="name"
                  name="name"
                  label="Your Name"
                  variant="outlined"
                  style={{ width: 276, marginBottom: 10, padding: '0 10px' }}
                  value={name}
                  onChange={(event) => changeName(event.target.value)}
                  error={errors.erName}
                  disabled={formDisabled}
                />
                <TextField
                  required
                  id="email"
                  name="email"
                  label="Email Address"
                  variant="outlined"
                  style={{ width: 276, marginBottom: 10, padding: '0 10px' }}
                  value={email}
                  onChange={(event) => changeEmail(event.target.value)}
                  error={errors.erEmail}
                  disabled={formDisabled}
                />
                <TextField
                  required
                  multiline
                  id="message"
                  name="message"
                  aria-label="minimum height"
                  minRows={6}
                  maxRows={10}
                  placeholder="Your Message..."
                  style={{ width: 270, margin: '0 10px', fontSize: 16 }}
                  value={message}
                  onChange={(event) => changeMessage(event.target.value)}
                  error={errors.erMessage}
                  disabled={formDisabled}
                />
                <LoadingButton
                  endIcon={<SendIcon />}
                  loading={loading}
                  loadingPosition="end"
                  variant="contained"
                  style={{ padding: '10px 20px', margin: '10px auto', display: 'flex' }}
                  onClick={submitForm}
                >
                  Send Your Message
                </LoadingButton>
              </Box>
            </Container>
          </div>
        </div>
        <div id="contact-result" className="hidden">
          <p className="mui--text-title" style={{ padding: 40 }}>Message was sent, thank you for contacting us.</p>
        </div>
      </Container>
    </Container>
  )
}
