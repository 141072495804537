import React, { useEffect, useState } from 'react';
import { Backdrop, Button, Fade, Modal } from '@mui/material';
import LoginForm from './LoginForm';

const styleLoginModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  maxWidth: '84%',
};

function Login(props) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return <>
    <Button variant="contained" onClick={handleOpen} style={{
      marginLeft: 'auto',
      marginRight: 20,
      backgroundColor: 'var(--color-accent)'
    }}>Login</Button>
    <Modal aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description" open={open} onClose={handleClose} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{
      timeout: 500
    }}>
      <Fade in={open}>
        <div style={styleLoginModal}>
        <LoginForm />
        </div>
      </Fade>
    </Modal>
  </>;
}
export default Login;