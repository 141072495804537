import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Button, Container, Grid } from '@mui/material/';
import LoginForm from '../PortalComponents/LoginForm';
import { CONFIG, PAGES } from '../../utils/Config';
//import PropTypes from 'prop-types'

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  borderRadius: 0
}));

function Header(props) {

  return (
    <Grid id="top" container spacing={2}>
      <Grid item xs={12}>
        <Item className="no-box-shadow header-bg padding-0">
          <Container className="cont-header" sx={{ paddingTop: props?.currentPage === PAGES.IDJ.name ? '10px' : '66px' }}>
            <img alt="crateQ" src="/img/logo-crateq.png" style={{ maxWidth: '200px' }} />
            <p className="mui--text-light mui--text-headline">batch downloader • music discovery • queue synchronization</p>
            <Button variant="flat" size="large" target="_blank" className="download-btn btn-md" href={`/installs/crateQ_win_v${CONFIG.CRATEQ_VERSION}.exe`}>WINDOWS</Button>
            <Button variant="flat" size="large" target="_blank" className="download-btn btn-md" href={`/installs/crateQ_mac_v${CONFIG.CRATEQ_VERSION}.dmg`}>MAC</Button>
            {props?.currentPage === PAGES.IDJ.name ?
              <Button variant="flat" size="large" className="download-btn btn-md" >iOS (coming soon)</Button>
              :
              <Button variant="flat" size="large" target="_blank" className="download-btn btn-md" href="https://apps.apple.com/us/app/crateq/id1513483202">iOS</Button>
            }
            <p className="mui--text-light mui--text-subhead">Current version {CONFIG.CRATEQ_VERSION}</p>

            {props?.currentPage === PAGES.IDJ.name ?
              <Container lg={12}>
                <img alt="iDJ" src="/img/logo-idj.png" style={{ maxWidth: '200px' }} />
                <LoginForm currentPage={props?.currentPage} idj={true} />
              </Container>
              :
              <Container lg={12} sx={{ display: { xs: "none", sm: "block" } }}>
                <img alt="crateQ" src="/img/header-img.jpg" style={{ width: '100%', maxWidth: '730px' }} />
              </Container>
            }

          </Container>
          <Container style={{
            maxWidth: '100%',
            backgroundImage: `url('/img/white-overlay-wave.png')`,
            position: 'relative',
            minHeight: '154px',
            backgroundSize: 'cover',
            marginTop: '-84px',
          }} />
        </Item>
      </Grid>
    </Grid>
  )
}

export default Header