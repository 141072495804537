import React from 'react'
import Draggable from 'react-draggable';
import { Card, CardHeader, CardMedia, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function VideoPlayer(props) {
  const desktop = useMediaQuery('(min-width:600px)');
  return (
    <Draggable disabled={!desktop}>
      <Card style={{
        position: 'fixed',
        width: desktop ? 400 : '100%',
        bottom: desktop ? 10 : 60,
        right: desktop ? 100 : 0,
        borderRadius: 30,
        display: (props.playerDisplayed) ? 'block' : 'none',
        border: 'solid 1px #bebebe',
        boxShadow: '1px 2px #bebebe',
      }}>
        <CardHeader
          action={
            <IconButton aria-label="Close" onClick={() => props.displayPlayer(false)}>
              <CloseIcon />
            </IconButton>
          }
          titleTypographyProps={{ variant: 'title', style: { fontWeight: 700 } }}
          subheaderTypographyProps={{ variant: 'body2' }}
          title={props.mediaPreview.title}
          subheader={props.mediaPreview.artist}
          style={{ cursor: 'move' }}
        />

        <CardMedia
          component={props.mediaPreview.isVideo ? 'video' : 'audio'}
          src={props.mediaPreview.url}
          onContextMenu={(e) => e.preventDefault()}
          onCanPlay={(e) => props.onCanPlay()}
          autoPlay
          controls
          controlsList="nodownload noplaybackrate" />
      </Card>
    </Draggable>
  )
}