import { useState, useEffect } from "react";
import * as utils from '../../utils/utils';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Container } from "@mui/material";

function ScrollToTop(props) {
  const [is_visible, setIsVisible] = useState(false);

  useEffect(() => {
    document.addEventListener("scroll", toggleVisibility);
    return function() {
      document.removeEventListener("scroll", toggleVisibility);
    }
  }, [])

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }

    return (
      <Container sx={{ display: { lg: 'block', xs: 'none'}}}>
        {is_visible && (
           <ArrowUpwardIcon sx={{ fontSize: { lg: 46, xs: 40 }, bottom: {lg : '96px', xs: '72px'}}} onClick={() => utils.scrollToTop()}
           style={{
            cursor: 'pointer', 
            position: 'fixed', 
            zIndex: 99999, 
            display: 'inline',
            right: '32px',
            color: '#fff',
            backgroundColor: 'rgba(35,35,35,.2)',
            borderRadius: '50%'
          }}/>
        )}
      </Container>
    );
}
export default ScrollToTop;