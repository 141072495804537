import { Box, Button } from '@mui/material';
import * as React from 'react';

const list_days = [
  { "name": "7 Days", "value": 7 },
  { "name": "30 Days", "value": 30 },
  { "name": "60 Days", "value": 60 },
  { "name": "90 Days", "value": 90 },
  { "name": "120 Days", "value": 120 },
  { "name": "1 Year", "value": 365 },
  { "name": "All Time", "value": 9999 }
]

function ChartsList(props) {
  return (
    <Box sx={{ bgcolor: 'background.paper' }} style={{ display: 'flex', justifyContent: 'space-around', padding: 20, flexWrap: 'wrap', gap: '12px' }}>
      {list_days.map((item) => (
        <Button
          key={item.value}
          variant="contained"
          onClick={() => { props.getCharts(item.value) }}
          sx={{minWidth : {lg: 120, xs: 98}}}
        >{item.name}</Button>
      ))}
    </Box>
  );
}


export { ChartsList as default };