import * as React from 'react';
import { useNavigate } from 'react-router-dom'
import { FunctionsContext } from './FunctionsContext';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import HomeIcon from '@mui/icons-material/Home';
import ClassIcon from '@mui/icons-material/Class';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import CloudIcon from '@mui/icons-material/Cloud';
import BarChartIcon from '@mui/icons-material/BarChart';
import { PAGES } from '../../utils/Config'

function BottomNav() {
  const [value, setValue] = React.useState('Home');

  let navigate = useNavigate();

  let functionsContext = React.useContext(FunctionsContext);
  return (
    <Box sx={{ pb: 7, display: {sm: 'none', xs: 'block'} }} >
      <CssBaseline />
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {    
            if(newValue !== 'Genres')
              setValue(newValue);
          }}
        >
          {/*<BottomNavigationAction label={PAGES.HOME.displayName} icon={<HomeIcon />} value={PAGES.HOME.displayName} onClick={(e)=>{ navigate(PAGES.PORTAL.path) }} style={{fontWeight: 600}} />*/}
          <BottomNavigationAction label={PAGES.LATEST.displayName} icon={<HomeIcon />} value={PAGES.LATEST.displayName} onClick={(e)=>{ navigate(PAGES.LATEST.path) }} style={{fontWeight: 600}} />
          <BottomNavigationAction label={PAGES.GENRES.displayName} icon={<ClassIcon />} value={PAGES.GENRES.displayName} onClick={(e)=>{ functionsContext.toggleGenreDrawer(true); }} style={{fontWeight: 600}} />
          <BottomNavigationAction label={PAGES.CRATE.displayName} icon={<ShoppingBasketIcon />} value={PAGES.CRATE.displayName}  onClick={(e)=>{ navigate(PAGES.CRATE.path) }}  style={{fontWeight: 600}} />
          {/*<BottomNavigationAction label={PAGES.CLOUD.displayName} icon={<CloudIcon />} value={PAGES.CLOUD.displayName}  onClick={(e)=>{ navigate(PAGES.CLOUD.path) }} style={{fontWeight: 600}} />*/}
          {/*<BottomNavigationAction label={PAGES.CHARTS.displayName} icon={<BarChartIcon />} value={PAGES.CHARTS.displayName}  onClick={(e)=>{ navigate(PAGES.CHARTS.path) }}  style={{fontWeight: 600}} />*/}
        </BottomNavigation>
      </Paper>
    </Box>
  );
}
export default BottomNav