import { Grid, Link } from '@mui/material'
import { CONFIG } from '../../utils/Config';

function Download(props) {
  return (
    <Grid container spacing={2} className="header-bg">
      <Grid item xs={12}>
        <Grid container className='cont-download-btn'>
          <Grid item xs={4}>
            <Link target="_blank" href={`/installs/crateQ_win_v${CONFIG.CRATEQ_VERSION}.exe`}>
              <img src="/img/logo-win.png" style={{ height: '70px' }} alt="Download Windows" />
              <p className="mui--text-title mui--text-light">Download</p>
              <p className="mui--text-display1 mui--text-light font-600">Windows</p>
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link target="_blank" href={`/installs/crateQ_mac_v${CONFIG.CRATEQ_VERSION}.dmg`}>
              <img src="/img/logo-mac.png" style={{ height: '70px' }} alt="Download Mac" />
              <p className="mui--text-title mui--text-light">Download</p>
              <p className="mui--text-display1 mui--text-light font-600">Mac OS</p>
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link target="_blank" href="https://apps.apple.com/us/app/crateq/id1513483202">
              <img src="/img/logo-ios.png" style={{ height: '70px' }} alt="Download iOS" />
              <p className="mui--text-title mui--text-light">Download</p>
              <p className="mui--text-display1 mui--text-light font-600">iOS</p>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Download