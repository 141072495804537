import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
//import MediaCard from './PortalComponents/MediaCard'; 
import TopNav from './PortalComponents/TopNav';
import GenresDrawer from './PortalComponents/GenresDrawer';
import MediaTable from './PortalComponents/MediaTable';
import VideoPlayer from './PortalComponents/VideoPlayer';
import * as utils from '../utils/utils';
import * as APIClient from '../utils/APIClient';
import ChartsList from './PortalComponents/ChartsList';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { FunctionsContext } from './PortalComponents/FunctionsContext'
import { FunctionsLoginContext } from './PortalComponents/FunctionsLoginContext'
import BottomNav from './PortalComponents/BottomNav';
import { CONFIG, PAGES } from '../utils/Config';

const Portal = React.memo(function Portal(props) {
  const [currentPage, setCurrentPage] = useState('');
  const [mediaList, setMediaList] = useState([]);
  const [previewList, setPreviewList] = useState([]);
  const [crateList, setCrateList] = useState([]); // mediaId list
  const [loading, setLoading] = useState(true);
  const [pageNum, setPageNum] = useState(CONFIG.PAGE_START_INDEX);
  const [rowsCount, setRowsCount] = useState(25);
  const [total, setTotal] = useState(1);
  const [pagesCount, setPagesCount] = useState(1);
  const [mediaPreview, setMediaPreview] = useState({ title: '', artist: '', url: '', isVideo: false });
  const [playerDisplayed, setPlayerDisplayed] = useState(false);
  const [mediaActionDisabled, setMediaActionDisabled] = useState(false);
  const [mediaActionLoading, setMediaActionLoading] = useState(false);
  const [filter, setFilter] = useState({ rows: rowsCount, page: pageNum });
  const [searchQuery, setSearchQuery] = useState('');
  const [displayChartList, setDisplayChartList] = useState(false);
  const [mode, setMode] = React.useState('light');
  const [genreDrawOpen, setGenreDrawOpen] = React.useState(false);
  const [genreList, setGenreList] = React.useState([]);

  const functionsLoginContext = useContext(FunctionsLoginContext);

  const functionsContext = React.useMemo(
    () => ({
      mode: mode,
      genreList: genreList,
      toggleGenreDrawer: (isOpen) => {
          setGenreDrawOpen(isOpen);
      },
      
      toggleColorMode: () => {
        let newMode = mode === 'light' ? 'dark' : 'light';
        setMode(newMode);
        localStorage.setItem('theme', newMode);
      },

      genreSlangURL: (genreTitle) => {
        genreTitle = genreTitle.trim();
        genreTitle = genreTitle.replaceAll(' / ', '-');
        genreTitle = genreTitle.replaceAll('/', '-');
        genreTitle = genreTitle.replaceAll(' > ', '>');
        genreTitle = genreTitle.replaceAll(' - ', '-');
        genreTitle = genreTitle.replaceAll(' ', '_');
        return genreTitle;
      },
      
      playMedia: (media) => {
        setMediaPreview({
          title: media.title,
          artist: media.artist,
          url: media.preview,
          isVideo: media.video,
        });
        setAsViewed(media);
        setMediaActionLoading(true);
        displayPlayer(true);
    
        APIClient.previewMedia(functionsLoginContext.user.token, media.mediaId).then((data) => {
          if (data.eCode === 0) { }
        });
      },
    
      downloadMedia: (media, isVideo) => {
        setMediaActionLoading(true);
        setMediaActionDisabled(true);
        setAsViewed(media);
        APIClient.downloadMedia(functionsLoginContext.user.token, media.mediaId, isVideo).then((data) => {
          if (data.eCode === 0) {
            functionsLoginContext.showAlert({
              type: 'success',
              message: isVideo ? "Video is downloading" : "Audio is downloading"
            });
            let downloadWindow = document.createElement('iframe');
            downloadWindow.name = "download";
            downloadWindow.src = data.data.url;
            downloadWindow.style.display = 'none';
    
            contDownload.current.appendChild(downloadWindow);
            if (currentPage === PAGES.CRATE.name) {
              getCrate();
            } else {
              refreshQueueList();
            }
          }
          else if (data.eCode === -1) {
            functionsLoginContext.showAlert({
              type: 'error',
              message: data.eMsg
            });
            setLoading(false);
            setMediaActionLoading(false);
            functionsLoginContext.userLogout();
          }
          else {
            functionsLoginContext.showAlert({
              type: 'error',
              message: data.eMsg
            });
          }
        })
          .then(() => {
            setMediaActionLoading(false);
            setMediaActionDisabled(false);
          })
      },
    
      updateQueue: (media) => {
        setMediaActionLoading(true);
        setMediaActionDisabled(true);
        APIClient.updateQueue(functionsLoginContext.user.token, media.mediaId).then((data) => {
          if (data.eCode === 0) {
            functionsLoginContext.showAlert({
              type: 'success',
              message: data.data.message
            });
            if(data.data.message.includes('added'))
            {
              if(!crateList.includes(media.mediaId))
              {
                setCrateList([...crateList, media.mediaId])
              }
            }
            else if(data.data.message.includes('removed'))
            {
              if(crateList.includes(media.mediaId))
              {
                let tmpCrateList = [...crateList];
                tmpCrateList.splice(tmpCrateList.indexOf(media.mediaId), 1)
                setCrateList(tmpCrateList)
              }
            }
            
            if (currentPage === PAGES.CRATE.name) {
              getCrate();
            }
            else
            {
              refreshQueueList();
            }
          }
          else if (data.eCode === -1) {
            functionsLoginContext.showAlert({
              type: 'error',
              message: data.eMsg
            });
            setLoading(false);
            setMediaActionLoading(false);
            functionsLoginContext.userLogout();
          }
          else {
            functionsLoginContext.showAlert({
              type: 'error',
              message: data.eMsg
            });
          }
        })
          .then(() => {
            setMediaActionLoading(false);
            setMediaActionDisabled(false);
          })
      },

      addToQueue: (media) => {
        setMediaActionLoading(true);
        setMediaActionDisabled(true);
        APIClient.updateQueue(functionsLoginContext.user.token, media.mediaId).then((data) => {
          if (data.eCode === 0) {
            functionsLoginContext.showAlert({
              type: 'success',
              message: data.data.message
            });
            if(!crateList.includes(media.mediaId))
            {
              setCrateList([...crateList, media.mediaId])
            }
            
            if (currentPage === PAGES.CRATE.name) {
              getCrate();
            }
          }
          else if (data.eCode === -1) {
            functionsLoginContext.showAlert({
              type: 'error',
              message: data.eMsg
            });
            setLoading(false);
            setMediaActionLoading(false);
            functionsLoginContext.userLogout();
          }
          else {
            functionsLoginContext.showAlert({
              type: 'error',
              message: data.eMsg
            });
          }
        })
          .then(() => {
            setMediaActionLoading(false);
            setMediaActionDisabled(false);
          })
      },

      removeFromQueue: (media) => {
        setMediaActionLoading(true);
        setMediaActionDisabled(true);
        APIClient.updateQueue(functionsLoginContext.user.token, media.mediaId).then((data) => {
          if (data.eCode === 0) {
            functionsLoginContext.showAlert({
              type: 'success',
              message: data.data.message
            });

            if(crateList.includes(media.mediaId))
            {
              let tmpCrateList = [...crateList];
              tmpCrateList.splice(tmpCrateList.indexOf(media.mediaId), 1)
              setCrateList(tmpCrateList)
            }
            
            if (currentPage === PAGES.CRATE.name) {
              getCrate();
            }
          }
          else if (data.eCode === -1) {
            functionsLoginContext.showAlert({
              type: 'error',
              message: data.eMsg
            });
            setLoading(false);
            setMediaActionLoading(false);
            functionsLoginContext.userLogout();
          }
          else {
            functionsLoginContext.showAlert({
              type: 'error',
              message: data.eMsg
            });
          }
        })
          .then(() => {
            setMediaActionLoading(false);
            setMediaActionDisabled(false);
          })
      },
      updateRowsCount: (count) => {
        localStorage.setItem('rowsCount', count);
        setPageNum(CONFIG.PAGE_START_INDEX);
        setRowsCount(count);
        let tempFilter = filter;
        tempFilter.rows = count;
        tempFilter.page = CONFIG.PAGE_START_INDEX;
        setFilter(tempFilter)
        getMedia();
      },
    
      updatePage: (pageNum) => {
        setPageNum(pageNum);
        let tempFilter = filter;
        tempFilter.page = pageNum;
        setFilter(tempFilter)
        getMedia();
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPage, genreList, crateList, mode, functionsLoginContext.user],
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          primary: {
            main: '#1a459a',
            dark: '#001f54'
          },
          secondary: {
            main: mode === 'light' ? '#1a459a' : '#FEB927'
          },
          grey : {
            main: mode === 'light' ? '#b4b4b4' : '#676767',
            light: '#b4b4b4',
            dark: '#676767',
            contrastText: '#fff',
          }
        },
      }),
    [mode],
  );

  const contDownload = useRef(null);
  const params = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    setDisplayChartList(false);
    let tmpFilter = filter;
    let storageRowsCount = localStorage.rowsCount
    if (storageRowsCount) {
      setRowsCount(parseInt(storageRowsCount));
      tmpFilter.rows = storageRowsCount;
    }

    let theme = localStorage.getItem('theme');
    if(theme === 'light' || theme === 'dark') {
      setMode(theme);
    }

    async function refreshData() {
      await refreshQueueList();
      getPreviewHistory();
      
  
      if (params.page) {
        console.log(params.page);
        setCurrentPage(params.page);
        if (params.page === PAGES.GENRES.name) {
          if (params.oid) {
            tmpFilter.genres = [parseInt(params.oid)];
            setFilter(tmpFilter);
          }
          getMedia();
        }
        else if (params.page === PAGES.CRATE.name) {
          getCrate();
          setSearchQuery('');
          delete tmpFilter.genres;
          setFilter(tmpFilter);
        }
        else if (params.page === PAGES.CLOUD.name) {
          getDownloadHistory();
          setSearchQuery('');
          delete tmpFilter.genres;
          setFilter(tmpFilter);
        }
        else if (params.page === PAGES.CHARTS.name) {
          getCharts(7);
          //setDisplayChartList(true);
          setSearchQuery('');
          delete tmpFilter.genres;
          setFilter(tmpFilter);
        }
        else if (params.page === PAGES.SEARCH.name) {
          if (params.oid) {
            handleSearch(params.oid);
          }
          else {
            navigate(PAGES.PORTAL.path);
          }
        }
        else if (params.page === PAGES.LATEST.name) {
          setCurrentPage(PAGES.LATEST.name);
          tmpFilter.page = CONFIG.PAGE_START_INDEX;
          delete tmpFilter.genres;
          delete tmpFilter.keywords;
          setSearchQuery('');
          setFilter(tmpFilter);
          getMedia();
        }
      }
      else // Portal homepage
      {
        setCurrentPage(PAGES.PORTAL.name);
        tmpFilter.page = CONFIG.PAGE_START_INDEX;
        delete tmpFilter.genres;
        delete tmpFilter.keywords;
        setSearchQuery('');
        setFilter(tmpFilter);
        getMedia();
      }
      getGenresList();
    }
    refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.page, params.oid]);

  const getGenresList = () => {
    APIClient.getGenres(functionsLoginContext.user.token).then((genres) => {
      genres && setGenreList(genres);
    })
  }

  const getMedia = (cached = true) => {
    setLoading(true);
    setMediaActionLoading(true);
    let mediaRequest;
    if (cached) {
      mediaRequest = APIClient.getMediaCache(functionsLoginContext.user.token, filter);
    }
    else {
      mediaRequest = APIClient.getMedia(functionsLoginContext.user.token, filter);
    }

    mediaRequest.then((data) => {
      if (data.eCode === 0) {
        setLoading(false);
        setMediaActionLoading(false);
        setMediaList(data.data.data);
        setPageNum(data.data.page);
        setTotal(data.data.total);
        setPagesCount(data.data.pages);
      }
      else if (data.eCode === -1) {
        functionsLoginContext.showAlert({
          type: 'error',
          message: data.eMsg
        });
        setLoading(false);
        setMediaActionLoading(false);
        functionsLoginContext.userLogout();
      }
      else {
        functionsLoginContext.showAlert({
          type: 'error',
          message: data.eMsg
        });
        setLoading(false);
        setMediaActionLoading(false);
      }
      utils.scrollToTop();
    });
  }

  const getDownloadHistory = () => {
    setLoading(true);
    setMediaActionLoading(true);
    APIClient.getDownloadHistory(functionsLoginContext.user.token, filter).then((data) => {
      if (data.eCode === 0) {
        setLoading(false);
        setMediaActionLoading(false);
        setMediaList(data.data.data);
        setPageNum(data.data.page);
        setTotal(data.data.total);
        setPagesCount(data.data.pages);
      }
      else if (data.eCode === -1) {
        functionsLoginContext.showAlert({
          type: 'error',
          message: data.eMsg
        });
        setLoading(false);
        setMediaActionLoading(false);
        functionsLoginContext.userLogout();
      }
      else {
        functionsLoginContext.showAlert({
          type: 'error',
          message: data.eMsg
        });
        setLoading(false);
        setMediaActionLoading(false);
      }
      utils.scrollToTop();
    });
  }

  const getPreviewHistory = () => {
    APIClient.getPreviewHistory(functionsLoginContext.user.token).then((data) => {
      if (data.eCode === 0) {
        setPreviewList(data.data.media);
      }
      else if (data.eCode === -1) {
        functionsLoginContext.showAlert({
          type: 'error',
          message: data.eMsg
        });
        setLoading(false);
        setMediaActionLoading(false);
        functionsLoginContext.userLogout();
      }
    });
  }

  const getCharts = (days) => {
    setLoading(true);
    setMediaActionLoading(true);
    APIClient.getCharts(functionsLoginContext.user.token, days).then((data) => {
      if (data.eCode === 0) {
        setLoading(false);
        setMediaActionLoading(false);
        setMediaList(data.data.data);
        setPageNum(data.data.page);
        setTotal(data.data.total);
        setPagesCount(data.data.pages);
      }
      else if (data.eCode === -1) {
        functionsLoginContext.showAlert({
          type: 'error',
          message: data.eMsg
        });
        setLoading(false);
        setMediaActionLoading(false);
        functionsLoginContext.userLogout();
      }
      else {
        functionsLoginContext.showAlert({
          type: 'error',
          message: data.eMsg
        });
        setLoading(false);
        setMediaActionLoading(false);
      }
      utils.scrollToTop();
    });
  }

  const displayPlayer = (display) => {
    if (!display) {
      setMediaActionLoading(false);
      setMediaPreview({ title: '', artist: '', url: '', isVideo: false });
    }
    setPlayerDisplayed(display);
  }

  const onCanPlay = () => {
    setMediaActionLoading(false);
  }


  const handleSearch = (_searchQuery) => {
    let tmpFilter = filter;
    filter.page = CONFIG.PAGE_START_INDEX;
    if (_searchQuery.trim() !== '') {
      setSearchQuery(_searchQuery);
      filter.keywords = _searchQuery;
    }
    else {
      delete tmpFilter.keywords;
    }
    setFilter(tmpFilter)
    getMedia();
  }

  const getCrate = () => {
    setLoading(true);
    setMediaActionLoading(true);

    APIClient.getCrate(functionsLoginContext.user.token).then((data) => {
      if (data.eCode === 0) {
        setLoading(false);
        setMediaActionLoading(false);
        setMediaList(data.data.data);
        setPageNum(data.data.page);
        setTotal(data.data.total);
        setPagesCount(data.data.pages);
        let crateIds = [];
        data.data.data.map((media) => {
          crateIds.push(media.id);
        })
      }
      else if (data.eCode === -1) {
        functionsLoginContext.showAlert({
          type: 'error',
          message: data.eMsg
        });
        setLoading(false);
        setMediaActionLoading(false);
        functionsLoginContext.userLogout();
      }
      else {
        functionsLoginContext.showAlert({
          type: 'error',
          message: data.eMsg
        });
        setLoading(false);
        setMediaActionLoading(false);
      }
      utils.scrollToTop();
    });
  }

  const refreshQueueList = async () => {
    await APIClient.getCrate(functionsLoginContext.user.token).then((data) => {
      if (data.eCode === 0) {
        let crateIds = [];
        data.data.data.map((media) => {
          crateIds.push(media.id);
        })
        setCrateList(crateIds);
      }
      else if (data.eCode === -1) {
        functionsLoginContext.showAlert({
          type: 'error',
          message: data.eMsg
        });
        setLoading(false);
        setMediaActionLoading(false);
        functionsLoginContext.userLogout();
      }
    });
  }

  const setAsViewed = (media) => {
    if (previewList && previewList.length > 0) {
      if (!previewList.includes(media.mediaId)) {
        setPreviewList([...previewList, media.mediaId]);
      }
    }
    else {
      setPreviewList([media.mediaId]);
    }
  }

  return (
    /*
    <Grid container spacing={2} style={{marginTop: 0, paddingTop: 60, gap: '12px', justifyContent: 'center', backgroundColor: 'var(--color-primary)'}}>
      { mediaList.map((media) => (
        <Grid item key={media.mediaId} lg={5} xs={10}>
          <MediaCard data={media}/>
        </Grid>
      ))}
    </Grid>
    */
    <FunctionsContext.Provider value={functionsContext}>
      <ThemeProvider theme={theme}>
        <TopNav searchQuery={searchQuery} />
        {<GenresDrawer genreList={genreList} genreDrawOpen={genreDrawOpen} />}
        {displayChartList && <ChartsList getCharts={getCharts} />}
        <MediaTable
          currentPage={currentPage}
          mediaList={mediaList}
          loading={loading}
          pageNum={pageNum}
          rowsCount={rowsCount}
          total={total}
          pages={pagesCount}
          mediaActionDisabled={mediaActionDisabled}
          previewList={previewList}
          crateList={crateList}
        />
        <VideoPlayer
          mediaPreview={mediaPreview}
          playerDisplayed={playerDisplayed}
          displayPlayer={displayPlayer}
          onCanPlay={onCanPlay}
        />
        <Box sx={{ width: '100%', position: 'fixed', bottom: {sm : 0, xs : 56}, display: mediaActionLoading ? 'block' : 'none' }}>
          <LinearProgress sx={{ height: {lg :10, xs: 4 }}}/>
        </Box>
        <BottomNav/>
        <div id="contDownload" ref={contDownload}></div>
      </ThemeProvider>
      </FunctionsContext.Provider>
  );
});


export default Portal;
