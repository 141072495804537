import React from 'react';
import TopNav from './HomePageComponents/TopNav';
import Body from './Privacy/Body';

function PrivacyPage(props) {
  return (
    <>
      <TopNav />
      <Body />
    </>
  );
}

export default PrivacyPage;
