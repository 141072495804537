export const CONFIG = {
    CRATEQ_VERSION: '2.0.22',
    PAGE_START_INDEX: 0,
    TOKEN_COOKIE_NAME: 'cqtoken',
    PROVIDER_COOKIE_NAME: 'cqproviderId',
    SIGNATURE_COOKIE_NAME: 'cqsig',
}
export const PAGES = {
    HOME: { name: 'home', displayName: 'Home', path: '/' },
    PORTAL: { name: 'portal', displayName: 'Portal', path: '/portal' },
    LATEST: { name: 'latest', displayName: 'Latest Releases', path: '/portal/latest' },
    GENRES: { name: 'genre', displayName: 'Genres', path: '/portal/genre' },
    CRATE: { name: 'queue', displayName: 'Queue', path: '/portal/queue' },
    CLOUD: { name: 'cloud', displayName: 'Cloud', path: '/portal/cloud' },
    CHARTS: { name: 'trending', displayName: 'Trending', path: '/portal/trending' },
    SEARCH: { name: 'search', displayName: 'Search', path: '/portal/search' },

    APPS: { name: 'apps', displayName: 'Apps', path: '/apps' },
    LNRP: { name: 'lnrp', displayName: 'LNRP', path: '/lnrp' },
    SMASHVISION: { name: 'smashvision', displayName: 'SmashVision', path: '/smashvision' },
    IDJ: { name: 'idj', displayName: 'iDJPool', path: '/idjpool' },
    PRIVACY: { name: 'privacy', displayName: 'Privacy', path: '/privacy' },
}