import * as React from 'react'
import { Collapse, Divider, Grid, List, ListItemButton, ListItemText } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material';

export default function Faq(){
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);

    const handleClick1 = () => {
      setOpen1(!open1);
    };

    const handleClick2 = () => {
      setOpen2(!open2);
    };

    const handleClick3 = () => {
      setOpen3(!open3);
    };

    const handleClick4 = () => {
      setOpen4(!open4);
    };

    return (
      <Grid container style={{margin: '70px auto', padding: '0 20px', maxWidth: '1140px', textAlign: 'center' }}>
        <Grid item xs={12} style={{marginBottom: '70px'}}>
          <p className="mui--text-display2 font-600">FAQ</p>
          <p className="mui--text-headline text-secondry">If you have any more questions feel free to reach out.</p>
        </Grid>
        <Grid item xs={12}>
          <List>
            <ListItemButton onClick={handleClick1}>
              <ListItemText  disableTypography={true} className="mui--text-title faq-question font-600" primary="Which record pools is crateQ compaitible with?" /> {open1 ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open1} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                  <ListItemText disableTypography={true} className="text-secondry text-start mui--text-title faq-answer" primary="Currently crateQ works with iDJPool, Late Night Record Pool and SmashVision. We we are in the process of integrating a few others." />
              </List>
            </Collapse>
            <Divider />

                  <ListItemButton onClick={handleClick2}>
                    <ListItemText disableTypography={true} className="mui--text-title faq-question font-600" primary="Which dj programs can I use crateQ with?" /> {open2 ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={open2} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemText disableTypography={true} className="text-secondry text-start mui--text-title faq-answer" primary="If your dj software supports drag and drop, then crateQ will work. This includes SeratoDJ, Virtual DJ, Rekord Box, djay PRO, Cross DJ, DJUCED and MIXXX." />
                    </List>
                  </Collapse>
                  <Divider />

                  <ListItemButton onClick={handleClick3}>
                    <ListItemText  disableTypography={true} className="mui--text-title faq-question font-600" primary="What type of files are downloaded when using crateQ?" /> {open3 ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={open3} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemText disableTypography={true} className="text-secondry text-start mui--text-title faq-answer" primary="crateQ downloads the same exact file that you would've downloaded from the record pool using a web browser." />
                    </List>
                  </Collapse>
                  <Divider />

                  <ListItemButton onClick={handleClick4}>
                    <ListItemText disableTypography={true} className="mui--text-title faq-question font-600" primary="How does queueing work?" /> {open4 ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={open4} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemText disableTypography={true} className="text-secondry text-start mui--text-title faq-answer" primary="You can queue tracks from your record pool, crateQ will monitor and sync your record pool queue, to crateQ's queue. From here you can download one by one, or enable 'auto download' which will download your entire queue to your selected directory." />
                    </List>
                  </Collapse>
                  <Divider />

                </List>
              </Grid>
            </Grid>
    )
  
}
