import * as React from 'react';
import { useNavigate } from 'react-router-dom'
import { FunctionsContext } from './FunctionsContext';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { PAGES } from '../../utils/Config';

export default function GenresDrawer(props) {
  let navigate = useNavigate();
  let functionsContext = React.useContext(FunctionsContext);
  
  const toggleDrawer = (open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    functionsContext.toggleGenreDrawer(open);
  };

  const genresList = () => <Box sx={{
  }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
      <List>
        {props.genreList.map(genre =>
          <ListItem button key={genre.genreId} onClick={()=>{navigate(`${PAGES.GENRES.path}/${genre.genreId}/${functionsContext.genreSlangURL(genre.display_name)}`)}}>
              <ListItemText primary={genre.display_name} />            
          </ListItem>)}
      </List>
      <Divider />
      {/*
      <List>
        {['Test1', 'Test2', 'Test3'].map((text, index) => <ListItem button key={text}>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>)}
      </List>
        */}
    </Box>;

  return <div>
      <React.Fragment>
          <Drawer anchor='left' open={props.genreDrawOpen} onClose={toggleDrawer(false)}>
            {genresList()}
          </Drawer>
        </React.Fragment>
    </div>;
}