import { useEffect, useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { Backdrop, Box, Button, Fade, FormControl, InputLabel, Modal, MenuItem, Select, TextField, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import LoadingButton from '@mui/lab/LoadingButton';
import { FunctionsLoginContext } from './FunctionsLoginContext'
import * as APIClient from '../../utils/APIClient'
import { CONFIG, PAGES } from '../../utils/Config';

const styleLoginForm = {
  margin: 'auto',
  width: 400,
  bgcolor: 'background.paper',
  textAlign: 'start',
  p: { xs: 3, sm: 4 },
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  maxWidth: '100%',
  marginLeft: { xs: -3, sm: 'auto' }
  /*
  
    padding: 24px;
    margin-left: -24px;
  */
};

function LoginForm(props) {
  let navigate = useNavigate();
  const [providersList, setProvidersList] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [providerID, selectProvider] = useState('');
  const [hideProviderList, setHideProviderList] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(true);
  const [errors, setErrors] = useState({
    errProvider: false,
    errUsername: false,
    errPassword: false
  });
  const [formDisabled, setFormDisabled] = useState(false);

  const functionsLoginContext = useContext(FunctionsLoginContext);

  useEffect(() => {
    if (functionsLoginContext.providersList.length === 0) {
      APIClient.getProviders()
        .then((data) => {
          setProvidersList(data);
        });
    }
    else {
      setProvidersList(functionsLoginContext.providersList);
    }
  }, [functionsLoginContext.providersList]);


  useEffect(() => {
    if (providersList.length > 0) {
      let storedProviderID = localStorage.getItem(CONFIG.PROVIDER_COOKIE_NAME)
      if (storedProviderID) {
        selectProvider(storedProviderID);
      }
    }

  }, [providersList]);


  useEffect(() => {
    if (props?.currentPage) {
      let provider = providersList.find(
        (fProvider) => fProvider.crateq_name === props.currentPage || fProvider.display_name === props.currentPage
      );

      if (provider) {
        selectProvider(provider.crateq_provider_Id);
        setHideProviderList(true);
      }
    }
  }, [props, providersList]);

  const changeProvider = event => {
    selectProvider(event.target.value);
  };

  function changeUsername(val) {
    setUsername(val);
  }

  function changePassword(val) {
    setPassword(val);
  }

  function isLoading(loading) {
    setLoading(loading);
  }

  function disableForm(disable) {
    setFormDisabled(disable);
  }

  const changeRememberMe = () => {
    setRememberMe(!rememberMe)
  }

  function submitForm(e) {
    e.preventDefault();
    let newErrors = {
      errProvider: false,
      errUsername: false,
      errPassword: false
    };
    let allowSubmit = true;

    if (providerID === '') {
      newErrors.errProvider = true;
      allowSubmit = false;
    }

    if (username === '') {
      newErrors.errUsername = true;
      allowSubmit = false;
    }

    if (password === '') {
      newErrors.errPassword = true;
      allowSubmit = false;
    }

    setErrors(newErrors);

    if (allowSubmit) {
      disableForm(true);
      isLoading(true);

      APIClient.login(providerID, username, password, rememberMe)
        .then(data => {
          if (data.eCode === 0) {
            console.log('Login success');
            handleClose();

            rememberMe ? localStorage.setItem(CONFIG.PROVIDER_COOKIE_NAME, providerID) : null;

            functionsLoginContext.updateUser({ token: data.token, providerId: providerID }, rememberMe);
            functionsLoginContext.showAlert({
              type: 'success',
              message: 'Login successful'
            });
            //navigate(PAGES.PORTAL.path);
          } else {
            functionsLoginContext.showAlert({
              type: 'error',
              message: data.eMsg
            });
            disableForm(false);
            isLoading(false);
          }
        });
    }
  }

  return (
    <form>
      <Box sx={styleLoginForm}>
        <Typography id="transition-modal-title" variant="h6" component="h2">
          Login
        </Typography>
        {!hideProviderList &&
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Provider</InputLabel>
            <Select labelId="demo-simple-select-label" id="demo-simple-select" value={providerID} label="Provider" onChange={changeProvider} disabled={formDisabled} error={errors.errProvider}>
              {providersList.length > 0 && providersList.map(provider =>
                <MenuItem key={provider.crateq_provider_Id} value={provider.crateq_provider_Id}>{provider.display_name}</MenuItem>
              )}
            </Select>
          </FormControl>
        }

        <FormControl fullWidth>
          <TextField id="outlined-basic" label="Username" variant="outlined" onChange={event => changeUsername(event.target.value)} disabled={formDisabled} error={errors.errUsername} />
        </FormControl>
        <FormControl fullWidth>
          <TextField id="outlined-password-input" label="Password" type="password" autoComplete="current-password" onChange={event => changePassword(event.target.value)} disabled={formDisabled} error={errors.errPassword} />
        </FormControl>
        <FormControlLabel
          label="Remember me"
          control={<Checkbox
            checked={rememberMe}
            onChange={changeRememberMe}
            inputProps={{ 'aria-label': 'controlled' }}
            disabled={formDisabled}
          />}
        />
        <FormControl fullWidth>
          <LoadingButton onClick={submitForm} loading={loading} loadingIndicator="Logging..." variant="outlined" type="submit">
            Login
          </LoadingButton>
        </FormControl>
        {props?.idj &&
          <Box className='login-disclaimer'>Please contact iDJPool for <span>(Help, Login Issues, Billing, Support, Cancellation)</span> <a href="mailto:info@idjpool.com">info@idjpool.com</a></Box>
        }
      </Box>
    </form>
  );
}
export default LoginForm;