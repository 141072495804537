import { useState, useEffect, useMemo } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie';
import Homepage from './components/Homepage';
import AppsPage from './components/AppsPage';
import PortalPage from './components/PortalPage';
import PrivacyPage from './components/PrivacyPage';
import ScrollToTop from './components/HomePageComponents/ScrollToTop';
import Alerts from './components/PortalComponents/Alerts';
import { FunctionsLoginContext } from './components/PortalComponents/FunctionsLoginContext'
import * as APIClient from './utils/APIClient';
import { CONFIG, PAGES } from './utils/Config';

function App(props) {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [providersList, setProvidersList] = useState([]);
  const [alertProps, setAlertProps] = useState({ show: false, type: 'error', message: '' });
  const [user, setUser] = useState({ token: '', providerId: 0 });

  useEffect(() => {
    let token = sessionStorage.getItem(CONFIG.TOKEN_COOKIE_NAME);
    let providerId = localStorage.getItem(CONFIG.PROVIDER_COOKIE_NAME);

    if (!token) {
      token = localStorage.getItem(CONFIG.TOKEN_COOKIE_NAME);
    }

    if (token && providerId) {
      setUser({ token: token, providerId: parseInt(providerId) });
      setIsLoggedIn(true);
    }
    else {
      getProvidersList();
    }
  }, [])

  const getProvidersList = () => {
    APIClient.getProviders().then((providers) => {
      setProvidersList(providers)
    })
  }

  let hideTimeout;
  const showAlert = (alertProps) => {
    setAlertProps({ ...alertProps, show: true })
    hideTimeout && clearTimeout(hideTimeout)
    hideTimeout = setTimeout(() => {
      hideAlert()
    }, 15000);
  }

  const hideAlert = () => {
    setAlertProps({ show: false, type: 'success', message: '' })
  }

  const updateUser = (user, remember) => {
    setUser(user)
    if (remember) {
      localStorage.setItem(CONFIG.TOKEN_COOKIE_NAME, user.token)
    }
    else {
      sessionStorage.setItem(CONFIG.TOKEN_COOKIE_NAME, user.token)
    }
    setIsLoggedIn(true);
  }

  const userLogout = () => {
    APIClient.logout().then((data) => {
      if (data.eCode === 0) {
        sessionStorage.removeItem(CONFIG.TOKEN_COOKIE_NAME)
        localStorage.removeItem(CONFIG.TOKEN_COOKIE_NAME)

        setUser({ ...user, token: '' })

        setIsLoggedIn(false);
        if (user.providerId === 5) { // iDJPool
          navigate(PAGES.IDJ.path);
        }
      }
      else {
        showAlert({
          type: 'error',
          message: 'Error logging out'
        });
      }
    })
  }

  const LoginContext = useMemo(
    () => ({
      providersList: providersList,
      user: user,
      showAlert: showAlert,
      hideAlert: hideAlert,
      userLogout: userLogout,
      updateUser: updateUser
      // eslint-disable-next-line
    }), [providersList, user]);

  const renderPortalHome = () => {
    if (isLoggedIn) {
      return <PortalPage user={user} />
    }
    else {
      return <Homepage />
    }
  }

  return (
    <CookiesProvider defaultSetOptions={{ path: '/' }}>
      <FunctionsLoginContext.Provider value={LoginContext}>
        <div>
          <Routes>
            <Route path={PAGES.APPS.path} element={<AppsPage />} />
            <Route path={PAGES.LNRP.path} element={<Homepage title="LNRP - crateQ" />} />
            <Route path={PAGES.SMASHVISION.path} element={<Homepage title="SmashVision - crateQ" />} />
            <Route path={PAGES.IDJ.path} element={isLoggedIn ? <Navigate to={PAGES.PORTAL.path} replace /> : <Homepage title="iDJPool - crateQ" />} />
            <Route exact path="/portal/:page" element={isLoggedIn ? <PortalPage user={user} /> : <Navigate to='/' replace />} />
            <Route exact path="/portal/:page/:oid" element={isLoggedIn ? <PortalPage user={user} /> : <Navigate to='/' replace />} />
            <Route exact path="/portal/:page/:oid/:slang" element={isLoggedIn ? <PortalPage user={user} /> : <Navigate to='/' replace />} />
            <Route exact path={PAGES.PORTAL.path} element={renderPortalHome()} />
            <Route exact path={PAGES.PRIVACY.path} element={<PrivacyPage />} />
            <Route exact path={PAGES.HOME.path} element={isLoggedIn ? <Navigate to={PAGES.PORTAL.path} replace /> : <Homepage />} />
            <Route path="*" element={<Navigate to={PAGES.HOME.path} replace />} />
          </Routes>
          <ScrollToTop />
          <Alerts alertProps={alertProps} />
        </div>
      </FunctionsLoginContext.Provider>
    </CookiesProvider>
  )
}

export default App;
