import React, { useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import AccountCircle from '@mui/icons-material/AccountCircle';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import DarkModeSwitch from './DarkModeSwitch';
import { FunctionsContext } from './FunctionsContext';
import { FunctionsLoginContext } from './FunctionsLoginContext'
import { PAGES } from '../../utils/Config';

const settings = ['Logout'];
let searchQuery;
const TopNav = (props) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const functionsLoginContext = useContext(FunctionsLoginContext);
  const functionsContext = React.useContext(FunctionsContext);

  let navigate = useNavigate();

  useEffect(() => {
    setSearchQuery(props.searchQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.searchQuery]);

  const setSearchQuery = (query) => {
    searchQuery = query;
  }

  const clearSearchQuery = () => {
    searchQuery = '';
    navigate(PAGES.PORTAL.path);
  }

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = (target) => {
    if (target.id === "Logout") {
      functionsLoginContext.userLogout();
    }
    setAnchorElUser(null);
  };

  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: 4,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  }));



  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1),
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
  }));

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Container maxWidth={false} sx={{ padding: { xs: '0 4px', lg: '0 16px' } }}>
            <Toolbar disableGutters>
              <Container sx={{ maxWidth: { xs: 116, md: 120 }, padding: { xs: 0, lg: 0 } }}>
                <Link to={PAGES.PORTAL.path}>
                  <img alt="crateQ" src="/img/logo-crateq.png" style={{ mr: 2, maxWidth: '100%' }} />
                </Link>
              </Container>
              { false && //Disable mobile navbar menu
              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                >
                  <MenuItem onClick={() => { handleCloseNavMenu(); navigate(PAGES.LATEST.path) }}>
                    <Typography textAlign="center">{PAGES.LATEST.displayName}</Typography>
                  </MenuItem>
                  <MenuItem onClick={() => { handleCloseNavMenu(); functionsContext.toggleGenreDrawer(true); }}>
                    <Typography textAlign="center">{PAGES.GENRES.displayName}</Typography>
                  </MenuItem>
                  <MenuItem onClick={() => { handleCloseNavMenu(); navigate(PAGES.CRATE.path) }}>
                    <Typography textAlign="center">{PAGES.CRATE.displayName}</Typography>
                  </MenuItem>
                  {/*<MenuItem onClick={() => { handleCloseNavMenu(); navigate(PAGES.CLOUD.path) }}>
                    <Typography textAlign="center">{PAGES.CLOUD.displayName}</Typography>
                  </MenuItem>*/}
                  {/*<MenuItem onClick={() => { handleCloseNavMenu(); navigate(PAGES.CHARTS.path) }}>
                    <Typography textAlign="center">{PAGES.CHARTS.displayName}</Typography>
                  </MenuItem>*/}
                </Menu>
              </Box>
              }
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                <Tooltip title={PAGES.LATEST.displayName}>
                  <Button onClick={() => { handleCloseNavMenu(); navigate(PAGES.LATEST.path); }} sx={{ my: 2, color: 'white', display: 'block' }}>
                    {PAGES.LATEST.displayName}
                  </Button>
                </Tooltip>
                <Tooltip title={PAGES.GENRES.displayName}>
                  <Button onClick={() => { handleCloseNavMenu(); functionsContext.toggleGenreDrawer(true); }} sx={{ my: 2, color: 'white', display: 'block' }}>
                    {PAGES.GENRES.displayName}
                  </Button>
                </Tooltip>
                <Tooltip title={PAGES.CRATE.displayName}>
                  <Button onClick={() => { handleCloseNavMenu(); navigate(PAGES.CRATE.path); }} sx={{ my: 2, color: 'white', display: 'block' }}>
                    {PAGES.CRATE.displayName}
                  </Button>
                </Tooltip>
                {/*<Tooltip title={PAGES.CLOUD.displayName}>
                  <Button onClick={() => { handleCloseNavMenu(); navigate(PAGES.CLOUD.path); }} sx={{ my: 2, color: 'white', display: 'block' }}>
                    {PAGES.CLOUD.displayName}
                  </Button>
                </Tooltip>*/}
                {/*<Tooltip title={PAGES.CHARTS.displayName}>
                  <Button onClick={() => { handleCloseNavMenu(); navigate(PAGES.CHARTS.path); }} sx={{ my: 2, color: 'white', display: 'block' }}>
                    {PAGES.CHARTS.displayName}
                  </Button>
                  </Tooltip>*/}
              </Box>
              <DarkModeSwitch />
              <Search>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ 'aria-label': 'search' }}
                  onChange={(e) => { setSearchQuery(e.target.value) }}
                  defaultValue={searchQuery}
                  onKeyUp={(e) => { (e.key === "Enter") && navigate(`${PAGES.SEARCH.path}/${e.target.value}`); }}
                />
                {searchQuery !== '' &&
                  <IconButton onClick={() => { clearSearchQuery() }}>
                    <ClearIcon style={{ color: '#fff' }} />
                  </IconButton>
                }
                <IconButton onClick={() => { navigate(`${PAGES.SEARCH.path}/${searchQuery}`); }}>
                  <SearchIcon style={{ color: '#fff' }} />
                </IconButton>
              </Search>

              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <AccountCircle style={{ color: 'white' }} fontSize="large" />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem key={setting} onClick={(e) => handleCloseUserMenu(e.target)}>
                      <Typography textAlign="center" id={setting}>{setting}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </Box>
    </>
  );
};
export default TopNav;