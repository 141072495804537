import React from 'react';
import TopNav from './HomePageComponents/TopNav';
import Body from './AppsPageComponents/Body';

function AppsPage(props) {
  return (
    <>
      <TopNav />
      <Body />
    </>
  );
}

export default AppsPage;
