import { Container, Grid } from '@mui/material/';

function Demo(props) {

  return (
    <Container>
      <Grid container style={{ margin: '100px auto', maxWidth: '1140px', textAlign: 'center' }} sx={{ padding: { lg: '0 20px', xs: '0' } }}>
        <Grid item xs={12}>
          <h3 className='mui--text-display2 font-600'>Watch Video in LIVE MODE</h3>
          <p className='mui--text-title text-secondry'>Drag any downloaded file into any DJ app that supports Drag + Drop</p>
          <iframe title="crateQ Demo" id="crateq-demo" src={props.videoSrc === "vimeo" ? "https://player.vimeo.com/video/213032097?h=e9d185799d&title=0&byline=0&portrait=0" : "https://www.youtube.com/embed/y_0sBc6TQj4"} width="825" height="480" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Demo