import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material/';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  boxShadow: 'unset',
}));


function Icons(props) {

  return (
    <Grid container style={{ margin: '100px auto', padding: '0 20px', maxWidth: '1140px' }}>
      <Grid item lg={4} xs={12}>
        <Item>
          <Card sx={{ display: 'flex' }} className="no-box-shadow">
            <span className="flaticon-010-binocular skyblue-color"></span>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <CardContent sx={{ flex: '1 0 auto' }} className="icon-desc">
                <Typography component="div" variant="h5">
                  Powerful Options
                </Typography>
                <Typography variant="subtitle1" color="text.secondary" component="div">
                  Search, preview, queue, and download audio or video directly in crateQ's desktop app
                </Typography>
              </CardContent>
            </Box>
          </Card>
        </Item>
      </Grid>
      <Grid item lg={4} xs={12}>
        <Item>
          <Card sx={{ display: 'flex' }} className="no-box-shadow">
            <span className="flaticon-061-download skyblue-color"></span>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <CardContent sx={{ flex: '1 0 auto' }} className="icon-desc">
                <Typography component="div" variant="h5">
                  Batch Downloading
                </Typography>
                <Typography variant="subtitle1" color="text.secondary" component="div">
                  Download tracks you've queued from your record pool individually or in large batches
                </Typography>
              </CardContent>
            </Box>
          </Card>
        </Item>
      </Grid>
      <Grid item lg={4} xs={12}>
        <Item>
          <Card sx={{ display: 'flex' }} className="no-box-shadow">
            <span className="flaticon-038-cursor skyblue-color"></span>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <CardContent sx={{ flex: '1 0 auto' }} className="icon-desc">
                <Typography component="div" variant="h5">
                  Drag + Drop
                </Typography>
                <Typography variant="subtitle1" color="text.secondary" component="div">
                  Downloaded files can be dragged and dropped into any DJ software app that supports file drop
                </Typography>
              </CardContent>
            </Box>
          </Card>
        </Item>
      </Grid>
      <Grid item lg={4} xs={12}>
        <Item>
          <Card sx={{ display: 'flex' }} className="no-box-shadow">
            <span className="flaticon-178-random skyblue-color"></span>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <CardContent sx={{ flex: '1 0 auto' }} className="icon-desc">
                <Typography component="div" variant="h5">
                  Fully Syncable
                </Typography>
                <Typography variant="subtitle1" color="text.secondary" component="div">
                  Your record pool will sync their queue page with our desktop and mobile apps
                </Typography>
              </CardContent>
            </Box>
          </Card>
        </Item>
      </Grid>
      <Grid item lg={4} xs={12}>
        <Item>
          <Card sx={{ display: 'flex' }} className="no-box-shadow">
            <span className="flaticon-095-headset skyblue-color"></span>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <CardContent sx={{ flex: '1 0 auto' }} className="icon-desc">
                <Typography component="div" variant="h5">
                  Focus On Mixing
                </Typography>
                <Typography variant="subtitle1" color="text.secondary" component="div">
                  Easily toggle between widget or app mode while DJing, this allows you to use crateQ Desktop seamlessly without having to minimize your DJ Software or open web browsers
                </Typography>
              </CardContent>
            </Box>
          </Card>
        </Item>
      </Grid>
      <Grid item lg={4} xs={12}>
        <Item>
          <Card sx={{ display: 'flex' }} className="no-box-shadow">
            <span className="flaticon-139-smartphone skyblue-color"></span>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <CardContent sx={{ flex: '1 0 auto' }} className="icon-desc">
                <Typography component="div" variant="h5">
                  On The Go
                </Typography>
                <Typography variant="subtitle1" color="text.secondary" component="div">
                  Easily discover new music and queue your upcoming downloads from our mobile app (Android version will be released Summer of 2024)
                </Typography>
              </CardContent>
            </Box>
          </Card>
        </Item>
      </Grid>
    </Grid>
  )
}

export default Icons