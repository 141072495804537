import React from 'react';
import { useLocation, useNavigate, useContext } from 'react-router-dom';
import Login from '../PortalComponents/Login';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { PAGES } from '../../utils/Config';

function TopNav(props) {
  const location = useLocation();
  let navigate = useNavigate();

  return ( props?.currentPage !== PAGES.IDJ.name && 
  <div style={{
    position: 'fixed',
    backgroundColor: 'var(--color-primary)',
    width: '100%',
    zIndex: 100,
    padding: 14,
    top: 0,
  }}>
    <Stack direction="row" spacing={2}>
    <Button
        id="basic-button"
        aria-haspopup="true"
        style={{ color: '#FFF' }}
        onClick={() => {navigate(PAGES.HOME.path)}}
      >
        Home
      </Button>
      <Button
        id="basic-button"
        aria-haspopup="true"
        style={{ color: '#FFF' }}
        onClick={() => {navigate(PAGES.PRIVACY.path)}}
      >
        Privacy Policy
      </Button>
      {location.pathname === PAGES.PORTAL.path ? <Login {...props} /> : ''}
    </Stack>
    
  </div>)
}
export default TopNav;