import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet";
import TopNav from './HomePageComponents/TopNav';
import Header from './HomePageComponents/Header';
import Icons from './HomePageComponents/Icons';
import Features from './HomePageComponents/Features';
import Demo from './HomePageComponents/Demo';
import Download from './HomePageComponents/Download';
import Faq from './HomePageComponents/Faq';
import Footer from './HomePageComponents/Footer';
import { PAGES } from '../utils/Config';

function Homepage(props) {
  const [currentPage, setCurrentPage] = useState('');
  const location = useLocation();
  

  useEffect(() => {
    console.log(location.pathname.toLowerCase());
    if (location.pathname.toLowerCase() === `${PAGES.IDJ.path}`)
    {
      setCurrentPage(PAGES.IDJ.name);
    }
  }, [location])

  return (
    <div>
      <Helmet>
        {props.title ?
          <title>{props.title}</title>
          : ''}
      </Helmet>
      <TopNav currentPage={currentPage}/>
      <Header currentPage={currentPage}/>
      <Icons />
      {currentPage !== PAGES.IDJ.name && <Features />}
      <Demo videoSrc={props.title ? "vimeo" : "youtube"}/>
      <Download />
      <Faq />
      <Footer />
    </div>
  );
}

export default Homepage;
