import { Container, Button, Grid } from '@mui/material/'
import { CONFIG } from '../../utils/Config';

function Features(props) {

  return (
    <div style={{ backgroundColor: '#f2f5f7', paddingTop: '100px', paddingBottom: '60px' }}>
      <Grid container style={{ margin: '70px auto', padding: '0 20px', maxWidth: '1140px' }}>
        <Grid item lg={6} xs={12}>
          <Container style={{ position: 'relative' }} sx={{ left: { lg: '-70%', xs: '0' } }}>
            <img alt="feature" src="/img/feature-laptop.png" loading="lazy" className='img-feature' />
          </Container>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Grid container m={0}>
            <span className="flaticon-066-edit-1 skyblue-color icon-xs" style={{ marginRight: '4px' }}></span>
            <p className="mui--text-subhead font-600">FEATURES</p>
          </Grid>
          <p className="mui--text-display2 font-600">Distribute your content using our apps.</p>
          <ul className="feature-list">
            <li>View and manage your queue, wish list, crate, bin, favorites etc</li>
            <li>Batch download files from crateQ's queue</li>
            <li>Search, preview, queue, and download audio or video directly in the app</li>
            <li>Drag and drop any downloaded file into any DJ software that supports file drop</li>
            <li>Toggle between widget or app mode allowing users to use crateQ in a live environment</li>
            <li>Widget mode is designed to float on top of the DJ software for easy access</li>
            <li>Fullscreen mode for easier navigation</li>
            <li>Custom column views can be added or removed (artist, title, BPM, genre, quality, playlist date, year, editor, and length)</li>
          </ul>
          <Container className='cont-feat-btn'>
            <Button color="primary" variant="contained" target="_blank" className="btn-md" href={`/installs/crateQ_win_v${CONFIG.CRATEQ_VERSION}.exe`}>Download Windows</Button>
            <Button color="primary" variant="contained" target="_blank" className="btn-md" href={`/installs/crateQ_mac_v${CONFIG.CRATEQ_VERSION}.dmg`}>Download Mac</Button>
            <Button color="primary" variant="contained" target="_blank" className="btn-md" href="https://apps.apple.com/us/app/crateq/id1513483202">Download iOS</Button>
          </Container>
        </Grid>
      </Grid>
    </div>
  )
}

export default Features