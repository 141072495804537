
function Body(props){
    return (
      <div style={{
        padding: '50px',
        fontFamily: 'sans-serif',
        fontSize: 17,
        lineHeight: '21px',
        scrollBehavior: 'smooth !important',
        background: '#212529',
        color: '#FFF',
      }}>
        <div className="modal-content upgrade-content">

          <div className="modal-header title-container">
            <h2 className="white text-center font-700" style={{fontSize: "26px"}}>PRIVACY POLICY</h2>
          </div>

          <div className="modal-body privacy-body">
            <p>
              <i>Last updated June 2, 2023</i>
            </p>
            <p>
              Thank you for choosing to be part of our community at CrateQ, LLC (" Company ," "we," "us,"
              or "our").
              We are committed to protecting your personal information and your right to privacy. If you have
              any
              questions or concerns about this privacy notice or our practices with regard to your personal
              information, please contact or message us via the website chat support.
            </p>
            <p>
              This privacy notice describes how we might use your information if you:
            </p>
            <ul>
              <li>Visit our website at crateq.com</li>
              <li>Download and use our mobile application — crateQ</li>
              <li>Engage with us in other related ways ― including any sales, marketing, or events</li>
            </ul>
            <p>
              In this privacy notice, if we refer to:
            </p>
            <ul>
              <li>"<strong>Website</strong>," we are referring to any website of ours that references or links
                to this policy</li>
              <li>"<strong>App</strong>," we are referring to any application of ours that references or links
                to this policy, including
                any listed above</li>
              <li>"<strong>Services</strong>," we are referring to our Website, App, and other related
                services, including any sales,
                marketing, or events</li>
            </ul>
            <p>
              The purpose of this privacy notice is to explain to you in the clearest way possible what
              information we
              collect, how we use it, and what rights you have in relation to it. If there are any terms in
              this
              privacy notice that you do not agree with, please discontinue use of our Services immediately.
            </p>
            <p>
              Please read this privacy notice carefully, as it will help you understand what we do with the
              information that
              we collect.
            </p>

            <h5>TABLE OF CONTENTS</h5>

            <ol>
              <li>WHAT INFORMATION DO WE COLLECT?</li>
              <li>HOW DO WE USE YOUR INFORMATION?</li>
              <li>WILL YOUR INFORMATION BE SHARED WITH ANYONE?</li>
              <li>HOW LONG DO WE KEEP YOUR INFORMATION?</li>
              <li>DO WE COLLECT INFORMATION FROM MINORS?</li>
              <li>WHAT ARE YOUR PRIVACY RIGHTS?</li>
              <li>CONTROLS FOR DO-NOT-TRACK FEATURES</li>
              <li>DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</li>
              <li>DO WE MAKE UPDATES TO THIS NOTICE?</li>
            </ol>

            <p><strong>1. WHAT INFORMATION DO WE COLLECT?</strong></p>

            <p><u>Personal information you disclose to us</u></p>

            <p>
              In Short: We collect personal information that you provide to us.
            </p>
            <p> We collect personal information that you voluntarily provide to us when you register on the
              Services,
              express an
              interest in obtaining information about us or our products and Services, when you participate in
              activities
              on
              the Services or otherwise when you contact us.</p>

            <p>
              The personal information that we collect depends on the context of your interactions with us and the
              Services ,
              the choices you make and the products and features you use. The personal information we collect may
              include
              the
              following:</p>
            <ul>
              <li>Personal Information Provided by You. We collect names; phone numbers; email addresses; mailing
                addresses;
                usernames; passwords; contact preferences; and other similar information. Note: All personal
                information that you provide to us must be true, complete and accurate, and you must
                notify us
                of
                any changes to such personal information.</li>
              <li> Payment Data. We may collect data necessary to process your payment if you make purchases, such
                as
                your
                payment
                instrument number (such as a credit card number), and the security code associated with your
                payment
                instrument. Note: All payment data is stored by Stripe . You may find their privacy notice
                link(s) here:
                https://stripe.com/privacy.</li>
            </ul>

            <p><u>Information collected through our App</u></p>

            <p>In Short: We collect information regarding your push notifications, when you use our App.</p>
            <p>
              If you use our App, we also collect the following information:
              Push Notifications. We may request to send you push notifications regarding your account or certain
              features
              of
              the App. If you wish to opt-out from receiving these types of communications, you may turn them off
              in your
              device's settings.
              This information is primarily needed to maintain the security and operation of our App, for
              troubleshooting
              and
              for our internal analytics and reporting purposes.
            </p>


            <p><strong>2. HOW DO WE USE YOUR INFORMATION?</strong></p>
            <p>In Short: We process your information for purposes based on legitimate business interests, the
              fulfillment
              of
              our contract with you, compliance with our legal obligations, and/or your consent.</p>

            <p>We use personal information collected via our Services for a variety of business purposes described
              below.</p>
            <p> We
              process your personal information for these purposes in reliance on our legitimate business
              interests, in
              order
              to enter into or perform a contract with you, with your consent, and/or for compliance with our
              legal
              obligations. We indicate the specific processing grounds we rely on next to each purpose listed
              below.</p>
            <p>We use the information we collect or receive:</p>

            <ul>
              <li>To facilitate account creation and logon process. If you choose to link your account with us to
                a
                third-party
                account (such as your Google or Facebook account), we use the information you allowed us to
                collect
                from
                those
                third parties to facilitate account creation and logon process for the performance of the
                contract.</li>
              <li> To post testimonials. We post testimonials on our Services that may contain personal
                information.
                Prior to
                posting a testimonial, we will obtain your consent to use your name and the content of the
                testimonial. If
                you
                wish to update, or delete your testimonial, please contact or message us via the website chat
                support and be sure
                to
                include
                your name, testimonial location, and contact information.</li>
              <li>Request feedback. We may use your information to request feedback and to contact you about your
                use
                of our
                Services.</li>
              <li>To enable user-to-user communications. We may use your information in order to enable
                user-to-user
                communications with each user's consent.</li>
              <li> To manage user accounts. We may use your information for the purposes of managing our account
                and
                keeping it
                in
                working order.</li>
              <li>To fulfill and manage your orders. We may use your information to fulfill and manage your
                orders,
                payments,
                returns, and exchanges made through the Services.</li>
              <li>To administer prize draws and competitions. We may use your information to administer prize
                draws and
                competitions
                when you elect to participate in our competitions.</li>
              <li>To deliver and facilitate delivery of services to the user. We may use your information to
                provide
                you with
                the
                requested service.</li>
              <li> To respond to user inquiries/offer support to users. We may use your information to respond to
                your
                inquiries and solve any potential issues you might have with the use of our Services.</li>
              <li> To send you marketing and promotional communications. We and/or our third-party marketing
                partners may use
                the personal information you send to us for our marketing purposes, if this is in accordance
                with your marketing
                preferences. For example, when expressing an interest in obtaining information about us or our
                Services,
                subscribing to marketing or otherwise contacting us, we will collect personal information from
                you. You can
                opt-out of our marketing emails at any time (see the "WHAT ARE YOUR PRIVACY RIGHTS?" below).
              </li>
              <li> To deliver targeted advertising to you. We may use your information to develop and display
                personalized content
                and advertising (and work with third parties who do so) tailored to your interests and/or
                location and to
                measure its effectiveness.</li>
            </ul>
            <p>
              For other business purposes. We may use your information for other business purposes, such as
              data analysis,
              identifying usage trends, determining the effectiveness of our promotional campaigns and to
              evaluate and
              improve our Services , products, marketing and your experience. We may use and store this
              information in aggregated
              and anonymized form so that it is not associated with individual end users and does not include
              personal information.
            </p>

            <p><strong>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</strong></p>
            <p>In Short: We only share information with your consent, to comply with laws, to provide you with
              services, to
              protect your rights, or to fulfill business obligations.</p>

            <p>We may process or share your data that we hold based on the following legal basis:
              Consent: We may process your data if you have given us specific consent to use your personal
              information for
              a specific purpose.</p>

            <ul>
              <li>Legitimate Interests: We may process your data when it is reasonably necessary to achieve our
                legitimate
                business interests.</li>
              <li>Performance of a Contract: Where we have entered into a contract with you, we may process your
                personal
                information to fulfill the terms of our contract.</li>
              <li>Legal Obligations: We may disclose your information where we are legally required to do so in
                order
                to
                comply with applicable law, governmental requests, a judicial proceeding, court order, or legal
                process, such as in
                response to a court order or a subpoena (including in response to public authorities to meet
                national
                security or law enforcement requirements).</li>
              <li>Vital Interests: We may disclose your information where we believe it is necessary to
                investigate,
                prevent,
                or take action regarding potential violations of our policies, suspected fraud, situations
                involving
                potential
                threats to the safety of any person and illegal activities, or as evidence in litigation in
                which we
                are
                involved.
                More specifically, we may need to process your data or share your personal information in
                situations such as business transfers where we may need to share or transfer your information in
                connection with, or during
                negotiations of,
                any
                merger, sale of company assets, financing, or acquisition of all or a portion of our business to
                another
                company.</li>
            </ul>

            <p><strong>4. HOW LONG DO WE KEEP YOUR INFORMATION?</strong></p>
            <p>In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this
              privacy
              notice unless otherwise required by law.</p>
            <p>We will only keep your personal information for as long as it is necessary for the purposes set out
              in this
              privacy notice, unless a longer retention period is required or permitted by law (such as tax,
              accounting or
              other legal requirements). No purpose in this notice will require us keeping your personal
              information for
              longer than the period of time in which users have an account with us.</p>
            <p>When we have no ongoing legitimate business need to process your personal information, we will
              either delete
              or
              anonymize such information, or, if this is not possible (for example, because your personal
              information has
              been
              stored in backup archives), then we will securely store your personal information and isolate it
              from any
              further processing until deletion is possible.</p>

            <p><strong>5. DO WE COLLECT INFORMATION FROM MINORS?</strong></p>

            <p>In Short: We do not knowingly collect data from or market to children under 18 years of age.</p>
            <p>We do not knowingly solicit data from or market to children under 18 years of age. By using the
              Services,
              you
              represent that you are at least 18 or that you are the parent or guardian of such a minor and
              consent to
              such
              minor dependent’s use of the Services.</p>
            <p>If we learn that personal information from users less than
              18 years
              of
              age has been collected, we will deactivate the account and take reasonable measures to promptly
              delete such
              data
              from our records.</p>
            <p>If you become aware of any data we may have collected from children under age 18,
              please
              contact or message us via the website chat support.
            </p>

            <p><strong>6. WHAT ARE YOUR PRIVACY RIGHTS?</strong></p>
            <p>In Short: In some regions, such as the European Economic Area (EEA) and United Kingdom (UK), you
              have rights
              that allow you greater access to and control over your personal information. You may review, change,
              or
              terminate your account at any time.</p>

            <p>In some regions (like the EEA and UK), you have certain rights under applicable data protection
              laws. These
              may
              include the right (i) to request access and obtain a copy of your personal information, (ii) to
              request
              rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if
              applicable,
              to data portability. </p>
            <p> In certain circumstances, you may also have the right to object to the
              processing of
              your
              personal information. To make such a request, please contact or message us via the website chat
              support. We will
              consider
              and act upon any request in accordance with applicable data protection laws.</p>
            <p>If we are relying on your consent to process your personal information, you have the right to
              withdraw your
              consent at any time. Please note however that this will not affect the lawfulness of the processing
              before
              its
              withdrawal, nor will it affect the processing of your personal information conducted in reliance on
              lawful
              processing grounds other than consent.</p>
            <p>If you are a resident in the EEA or UK and you believe we are unlawfully processing your personal
              information,
              you also have the right to complain to your local data protection supervisory authority. You can
              find their
              contact details here: https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
            </p>
            <p>If you are a resident in Switzerland, the contact details for the data protection authorities are
              available
              here: https://www.edoeb.admin.ch/edoeb/en/home.html.</p>

            <p><u>Account Information</u></p>
            <p>If you would at any time like to review or change the information in your account or terminate your
              account,
              you
              can:</p>
            <ul>
              <li>Upon your request to terminate your account, we will deactivate or delete your account and
                information from
                our
                active databases. However, we may retain some information in our files to prevent fraud,
                troubleshoot
                problems,
                assist with any investigations, enforce our Terms of Use and/or comply with applicable legal
                requirements.</li>
              <li>Opt out of email marketing list at any time by
                clicking on
                the
                unsubscribe link in the emails that we send or by contacting or messaging us via the website
                chat support.
                You will
                then
                be removed from the marketing email list — however, we may still communicate with you, for
                example
                to send
                you
                service-related emails that are necessary for the administration and use of your account, to
                respond
                to
                service
                requests, or for other non-marketing purposes.</li>
            </ul>

            <p><strong>7. CONTROLS FOR DO-NOT-TRACK FEATURES</strong></p>

            <p> Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track
              ("DNT")
              feature or setting you can activate to signal your privacy preference not to have data about your
              online
              browsing activities monitored and collected. At this stage no uniform technology standard for
              recognizing
              and
              implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser
              signals or
              any
              other mechanism that automatically communicates your choice not to be tracked online.</p>
            <p>If a standard
              for
              online
              tracking is adopted that we must follow in the future, we will inform you about that practice in a
              revised
              version of this privacy notice.</p>

            <p><strong>8. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</strong></p>

            <p>In Short: Yes, if you are a resident of California, you are granted specific rights regarding access
              to your
              personal information.</p>
            <p>California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users
              who are
              California residents to request and obtain from us, once a year and free of charge, information
              about
              categories
              of personal information (if any) we disclosed to third parties for direct marketing purposes and the
              names
              and
              addresses of all third parties with which we shared personal information in the immediately
              preceding
              calendar
              year. If you are a California resident and would like to make such a request, please submit your
              request in
              writing to us via the website chat support.</p>
            <p>If you are under 18 years of age, reside in California, and have a registered account with a Service
              , you
              have
              the right to request removal of unwanted data that you publicly post on the Services . To request
              removal of
              such data, please contact or message us via the website chat support, and include the email
              address
              associated with your account and a statement that you reside in California. We will make sure the
              data is
              not
              publicly displayed on the Services , but please be aware that the data may not be completely or
              comprehensively
              removed from all our systems (e.g. backups, etc.).</p>

            <p><strong>9. DO WE MAKE UPDATES TO THIS NOTICE?</strong></p>

            <p>In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.</p>
            <p> We may update this privacy notice from time to time. The updated version will be indicated by an
              updated
              "Revised" date and the updated version will be effective as soon as it is accessible. If we make
              material
              changes to this privacy notice, we may notify you either by prominently posting a notice of such
              changes or
              by
              directly sending you a notification. We encourage you to review this privacy notice frequently to be
              informed of
              how we are protecting your information.</p>
          </div>

          <div className="modal-footer">

          </div>
        </div>
      </div>
    )
}

export default Body